.contact .contact-info{
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);

}

.contact .contact-info .info-item{
    -webkit-box-flex: 1;
    -ms-flex:1 1 25rem;
        flex:1 1 25rem;
    height: 20rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.contact .contact-info .info-item .icon{
    height: 5rem;
    width: 5rem;
    margin-bottom: 1rem;
    font-size: 4rem;
    border-radius: 50%;
    color: var(--main-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contact .contact-info .info-item h3{
    font-size: 2rem;
    color: var(--black);
    padding: 1rem 0rem;
}

.contact .contact-info .info-item p{
    font-size: 1.6rem;
    color: var(--grey);
    padding-bottom: 0.5rem;
}

.contact .contact-info .info-item p.gmail{
    text-transform: none;
}
 
.contact .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}