/*-- Quantity Box --*/
.quantity {
    display: inline-block; 
}
   
.quantity .input-text.qty {
    width: 3.5rem; 
    height: 4rem;
    padding: 0 5px;
    text-align: center;
    background-color: transparent;  
    border: 1px solid #ccc; 
} 
   
.quantity.buttons_added {
    text-align: left; 
    position: relative;
    white-space: nowrap;
    vertical-align: top; 
}

.quantity.buttons_added input {
    display: inline-block;
    margin: 0;
    vertical-align: top;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.quantity.buttons_added .minus,
.quantity.buttons_added .plus {
    padding: 7px 10px 8px;
    height: 4rem;
    color: var(--white);
    background-color: var(--white);
    border: 1px solid #ccc;
    cursor:pointer;
}

.quantity.buttons_added .minus {
    border-right: 0; 
    background-color: red;
}
   
.quantity.buttons_added .plus {
    border-left: 0;
    background-color: green;
}

.quantity.buttons_added .minus:hover,
.quantity.buttons_added .plus:hover {
    background: var(--black); 
}

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0; 
}
    
.quantity.buttons_added .minus:focus,
.quantity.buttons_added .plus:focus {
    outline: none; 
}