.services .box-container{
    display: grid; 
    grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
    gap: 1.5rem;
    margin: 2rem 0;
}
  
.service-item{ 
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.service-item .image{
    height: 25rem;
    width: 100%;
}

.service-item .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.service-item .content{
    padding: 2rem;
}

.service-item .content h3{
    font-size: 2rem;
    color:var(--black);
    padding-bottom: 1rem;
}

.service-item .content p{
    font-size: 1.5rem;
    line-height: 1.6;
    color: var(--grey);
}
