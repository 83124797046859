.login form {
  max-width: 40rem;
}

.login button{
  width: 100%;
}

.login button .btn{ 
  width: 100%;
}

.login form .link{ 
    font-size: 1.5rem;
    color: var(--main-color);
}

.login form .link:hover{
    text-decoration: underline;
}

.login form .forgot{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 1rem 0;
}
