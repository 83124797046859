.blog.grid{
    display: -webkit-box;
    display: -ms-flexbox; 
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 2rem;
}  
  
.blog-container{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 67rem;
        flex: 1 1 67rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.blog-container.grid .blog-items{
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
    gap: 1rem;
}
