.pages-no{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox; 
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 2rem 0;
}
  
.pages-no .container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.pages-no .item{
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    height: 5rem;
    width: 5rem;
    margin: 0rem 0.5rem;
    font-size: 2rem;
    border-radius: 0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pages-no .item.active,
.pages-no .item:hover{
    cursor: pointer;
    color: var(--white);;
    background-color: var(--main-color);
}

.pages-no .numbers{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
