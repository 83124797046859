.counting{
    width: 100%; 
    padding: 2rem 5%;
    margin: 1rem 0;
    min-height: 15rem;
    display: -webkit-box;   
    display: -ms-flexbox; 
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; 
    gap: 3rem;
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url("../../assets/Counter/Counter.png");
    background:         linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url("../../assets/Counter/Counter.png");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.counting .box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 25rem;
        flex: 1 1 25rem;
    text-align: center;
    padding: 2rem;
}

.counting .box .icon{
    color: var(--white);
    font-size: 6rem;
    text-align: center;
    margin: 0 auto;
}

.counting .box .count{
    display: inline-block;
    font-size: 3rem;
    color: var(--white);
    padding-bottom: 1rem;
}

.counting .box h3{
    font-size: 2rem;
    font-weight: 400;
    color: var(--white);
}
