@media (max-width: 991px){
    .mobile-menu { 
        display: block;
        position: fixed;
        top: 0; 
        left: 0; 
        height: 100%;
        width: 30rem; 
        z-index: 10000;
        overflow-y: auto; 
        max-height: 100%;
        background: var(--main-color);
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        -webkit-box-shadow: var(--box-shadow);
                box-shadow: var(--box-shadow);
    } 
    
    .mobile-menu::-webkit-scrollbar{
        width: 0.8rem; 
    }
    
    .mobile-menu::-webkit-scrollbar-thumb{
        border-radius: 1rem;
        background-color: var(--secondary-color);
    }
    
    .mobile-menu::-webkit-scrollbar-track{
        background: var(--white);
    }
    
    .mobile-menu #close-side-bar {
        position: absolute;
        top: 1rem;
        right: 2rem; 
        font-size: 4rem;
        cursor: pointer;
        color: var(--white);
    }
    
    .mobile-menu #close-side-bar:hover {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        color: var(--secondary-color);
    }
    
    .mobile-menu .mobile-navbar {
        padding-top: 8rem;
    }
}