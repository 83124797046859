.testimonial-slider{
    position: relative;
    padding: 2rem 20rem 4rem 20rem;
    overflow: hidden;
} 

.testimonial-slider .swiper{
    position: unset;
}
   
.testimonial-slider .swiper-button-next,
.testimonial-slider .swiper-button-prev{
    background-color: var(--secondary-color);
    color: var(--white);
    font-size: 1rem;
    height: 5rem;
    width: 5rem;
}

.testimonial-slider .swiper-button-prev:after, 
.testimonial-slider .swiper-button-next:after{
    font-size: 2rem;
}

.testimonial-slider .swiper-button-next{
    right: 5rem;
}

.testimonial-slider .swiper-button-prev{
    left: 5rem;
}

.testimonial-slider .swiper-button-next:hover,
.testimonial-slider .swiper-button-prev:hover{
    background-color: var(--main-color);
}

.testi-item{
    background: var(--white);
    border: var(--border);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    padding: 4rem 2rem;
    text-align: center;
    z-index: 0;
}

.testi-item .quote-left,
.testi-item .quote-right{
    font-size: 8rem;
    font-weight: 800;
    color: #ccc;
    z-index: -1;
}

.testi-item .quote-left{
    position: absolute;
    top: 1rem;
    left: 2rem;
}

.testi-item .quote-right{
    position: absolute;
    bottom: 1rem;
    right: 2rem;
}

.testi-item img{
    height: 8rem;
    width: 8rem;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 1rem;
}

.testi-item .rating{
    padding-bottom: 1rem;
}

.testi-item .rating .star{
    color: gold;
    font-size: 2rem;
}

.testi-item p{
    font-size: 1.6rem;
    color: var(--grey);
    padding: 2rem 0;
    line-height: 1.8;
}

.testi-item h3{
    color: var(--black);
    font-size: 2.5rem;
    padding-bottom: 0.5rem;
}

.testi-item span{
    font-size: 1.6rem;
    color: var(--grey);
}
