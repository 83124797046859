.scroll-to-top{
  bottom: 1rem !important;
  right: 1rem !important;
  height: 5rem !important;
  width: 5rem !important; 
  font-size: 3rem; 
  font-weight: 600;
  background-color: var(--white) !important;
  border: 0.2rem solid var(--main-color) !important;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s; 
  -o-transition: all 0.2s;
  transition: all 0.2s; 
  z-index: 999 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50% !important;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

.scroll-to-top:hover{
  background-color: var(--main-color) !important;
  cursor: pointer;
}

.scroll-to-top svg{
    fill: var(--main-color);
    font-weight: bold;
}

.scroll-to-top:hover svg{
  fill: var(--white);
}
