.MuiSlider-root {
    color: var(--main-color) !important;
}

.MuiSlider-rail { 
    width: 100%;
    height: 2px; 
    display: block; 
    opacity: 0.38;  
    position: absolute; 
    border-radius: 1px;
    background-color: var(--secondary-color);
}
  
.filter-btn{
    display: inline-block;
    padding: 1rem 3rem;
    color: var(--white);
    background-color: goldenrod;
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.filter-btn:hover{
    background-color: var(--black);
    cursor: pointer;
}
  
.price-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #ccc;
    padding: 5px;
    width: 11rem;
}

.price-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 2rem;
    color: var(--black);
    font-size: 14px;
    line-height: 1.2em;
    font-weight: 400;
    margin-bottom: 0px;
    border-radius: var(--border-radius);
}

.price-wrap-1, 
.price-wrap-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 0px;
}

.price-wrap_line {
    margin: 6px 0px 5px 5px;
}

.price-wrap #upper, 
.price-wrap #lower {
    width: 3rem;
    text-align: right;
    margin: 0;
    padding: 0;
    margin-right: 2px;
    background:  0;
    border: 0;
    outline: 0;
    color: #242424;
    font-size: 14px;
    line-height: 1.2em;
    font-weight: 400;
}

.price-wrap label {
    text-align: right;
    margin-top: 6px;
    padding-left: 2px;
}

.price-wrap p {
    text-align: right;
    margin-top: 6px;
    padding-left: 2px;
}

