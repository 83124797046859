.blog-details .comments{
    background-color: var(--white);;
    padding: 1rem;
    margin: 2rem 0;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow); 
} 

.blog-details .comments h4{  
    font-size: 2rem; 
    color: var(--black); 
    padding-bottom: 2rem;
} 

.blog-details .comments .comment{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    padding: 1rem 0;
    border-top: 0.1rem solid var(--secondary-color);
}

.blog-details .comments .item .image{
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    overflow: hidden;
}

.blog-details .comments .item .image img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.blog-details .comments .item .content{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
}

.blog-details .comments .item .content .intro{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.blog-details .comments .item .content .intro h6{
    font-size: 2rem;
    color: var(--black);
}

.blog-details .comments .item .content .intro p{
    font-size: 1.5rem;
    color: var(--main-color);
    padding: 0.5rem 0;
}

.blog-details .comments .item .content .intro .reply{
    font-size: 1.8rem;
    color: var(--black);
    cursor: pointer;
}

.blog-details .comments .item .content .text{
    font-size: 1.5rem;
    color: var(--grey);
    line-height: 1.5;
}
 
.blog-details .comments .item .comment.reply{
    margin-left: 10rem;
}


