.gallery .controls .button{
	color: var(--secondary-color); 
    padding: 1rem 2.5rem;
    font-size: 2rem;
    border-radius: 5rem; 
    border: 0.2rem solid var(--secondary-color);
}

.gallery .controls .button:hover,
.gallery .controls .button.active{
	cursor: pointer;
    color: var(--white);
    background-color: var(--secondary-color);
}

._3bkri {
    background-color: var(--main-color) !important;
}

._20cg_ {
	background-color: var(--main-color) !important;
}

._20cg_:hover {
	background-color: var(--main-color) !important;
}

._20cg_:focus {
	-webkit-box-shadow: 0px 0px 0px 3px var(--secondary-color) !important;
			box-shadow: 0px 0px 0px 3px var(--secondary-color) !important;
}
