.home{
  padding: 0;
  height: 1px; 
  min-height: 100vh;
  position: relative;
} 

.home-slider{
  position: relative;  
}  
 
.home-slider .swiper-button-next,
.home-slider .swiper-button-prev{
  background-color: var(--main-color);
  color: var(--white);
  font-size: 1rem;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  padding: 2rem;
}

.home-slider .swiper-button-next::after,
.home-slider .swiper-button-prev::after {
    font-size: 2rem;
    font-weight: bold;
    color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.home-slider .swiper-button-next:hover,
.home-slider .swiper-button-prev:hover {
  color: var(--white);
  background-color: var(--secondary-color);
}

.home-item{
  min-height: 100vh;
}

.home-item img{
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.home-item .content{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
  background:         linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
}

.home-item .content .text{
  text-align: center;
  width: 70rem;
}

.home-item .content h1{
  color: var(--white);
  font-size: 4.5rem;
  font-weight: 500;
}

.home-item .content p{
  color: var(--white);
  font-size: 2rem;
  line-height: 1.5;
  padding: 2rem 0;
}

.home-item .btn-container{
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.home-item .content .btn:first-child{
  background-color: var(--main-color);
}

.home-item .content .btn:first-child:hover{
  background-color: var(--secondary-color);
}
