.reservation .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.reservation iframe{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem;
        flex: 1 1 30rem;
}

/*----- Reservation Table -----*/
.reservation .booking-table{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50rem;
        flex: 1 1 50rem;
    background: var(--white);
    padding: 1rem 2rem;
}

.reservation .booking-table h2{
    text-align: center;
    color: var(--black);
    font-size: 3rem;
    padding-bottom: 1rem;
}

.reservation .booking-table .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.reservation .booking-table .input-box .box{
    width: 49%;
}

.reservation .booking-table .box{
    width: 100%;
    font-size: 1.6rem;
    color: var(--black);
    text-transform: none;
    background-color: transparent;
    border-bottom: 0.1rem solid var(--black);
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
}

.reservation .booking-table .box::placeholder{
    text-transform: capitalize;
    color: var(--grey);
}

.reservation .booking-table .box:focus{
    border-color: var(--main-color);
}

.reservation .booking-table textarea.box{
    width: 100%;
    height: 17rem;
    resize: none;
}

.reservation .booking-table .alert{
    font-size: 2rem;
    color: var(--secondary-color);
    padding-left: 1rem;
}
