.heading{
  margin-bottom: 2rem;
  text-align: center;
}

.heading h2 {
  position: relative;
  font-weight: 400;
  font-size: 3.5rem;
  color: var(--black);
  text-align: center;
  padding-bottom: 0.5rem;
}

.heading h2:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: var(--secondary-color);
}

.heading h2:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -50px;
  background-color: var(--secondary-color);
}