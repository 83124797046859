.blog-sidebar{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem; 
        flex: 1 1 30rem;  
}
  
.blog-sidebar .sidebar-item{
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    padding: 1.5rem;
    margin-bottom: 2rem;
} 

.blog-sidebar .box-container{ 
    padding: 1rem 0;
}

/*-- 1- Search --*/
.blog-sidebar .search .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2rem;
    border-radius: 5rem;
    overflow: hidden;
}

.blog-sidebar .search .input-box input{
    width: 100%;
    padding: 1.5rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.5);
    overflow: hidden;
    border-bottom-left-radius: 5rem;
    border-top-left-radius: 5rem;
}

.blog-sidebar .search .input-box input:focus{
    border-color: var(--secondary-color);
}

.blog-sidebar .search .input-box label{
    color: var(--white);
    background-color: var(--main-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem 2rem;
    font-size: 1.8rem;
    cursor: pointer;
}

.blog-sidebar .search .input-box label:hover{
    background-color: var(--secondary-color);
}

/*-- 2- Category --*/
.blog-sidebar .category .item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.5rem 0 1rem 0;
    border-bottom: 0.1rem solid var(--main-color);
}

.blog-sidebar .category .item a{
    font-size: 2rem;
    color: var(--black);
    font-weight: 400;
    cursor: pointer;
}

.blog-sidebar .category .item a:hover{
    color: var(--main-color);
}

.blog-sidebar .category .item p{
    font-size: 1.8rem;
    color: var(--secondary-color);
}

/*-- 3- Recent Post --*/
.blog-sidebar .blog-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: none; 
            box-shadow: none;
    gap: 1rem;
    margin-bottom: 1rem;
}

.blog-sidebar .blog-item .image{
    height: 8rem; 
    width: 12rem;
}

.blog-sidebar .blog-item .content{
    flex: 1 1 20rem;
    padding: 0;
}

.blog-sidebar .blog-item .content .main-heading{
    display: inline-block;
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--black);
}

.blog-sidebar .blog-item .content .main-heading:hover{
    color: var(--main-color);
}

.blog-sidebar .blog-item .date{
    display: none;
}

.blog-sidebar .blog-item .details{
    display: none;
}


/*-- 4- Tags --*/
.blog-sidebar .tags .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
}

.blog-sidebar .tag-item{
    background-color: var(--main-color);
    color: var(--white);
    font-size: 2rem;
    padding: 1rem 1.5rem;
    border-radius: 5rem;
}

.blog-sidebar .tag-item:hover{
    background-color: var(--secondary-color);
    cursor: pointer;
}
