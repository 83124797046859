.menu{
    padding: 1rem 5%;
}
 
.menu-btn-tabs{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    list-style-type: none;
    gap: 1.5rem;
}

.menu .box-container{
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
    gap: 2rem;
    margin: 2rem 0;
}

.menu-item{
    border-radius: 0.5rem;
    padding: 2rem;
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.menu-item .intro{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 1rem;
}

.menu-item .intro h4{
    font-size: 2rem;
}

.menu-item .intro span{
    font-size: 2rem;
    color: var(--main-color);
}

.menu-item p{
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--grey);
}
