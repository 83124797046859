.team{
  padding: 2rem 5%;
}

.team .box-container {
  display: -ms-grid; 
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1rem;
  margin: 2rem 0;
}  
  
.team-item { 
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

.team-item .image {
  height: 25rem;
  overflow: hidden;
}

.team-item .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-item:hover .image img{
  -webkit-transform: scale(1.08); 
          transform: scale(1.08);
}

.team-item .content{
  text-align: center;
  background-color: var(--white);
  padding: 2rem;
}

.team-item .content h3{
  color: var(--black);
  font-size: 2.5rem;
}

.team-item .content p{
  color: var(--main-color);
  font-size: 1.8rem;
  padding: 1rem 0;
}
  
.team-item .icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 1rem;
  gap: 1rem;

}
  
.team-item .icon-container a{
  height: 4.5rem;
  width: 4.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.8rem;
  border-radius: 50%;
  color: var(--main-color);
  border: 0.1rem solid var(--main-color);
}

.team-item .icon-container a:hover{
  color: var(--white);
  background-color: var(--main-color);
}
  