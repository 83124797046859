.checkout{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    gap: 2rem;
    -ms-flex-wrap: wrap; 
        flex-wrap: wrap; 
}   

.checkout-item{  
    background-color: var(--white);
    padding: 1rem;
    margin-bottom: 2rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.checkout .box-1{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 66rem;
        flex: 1 1 66rem;
}

.checkout .box-2{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33rem;
        flex: 1 1 33rem;
}

/*----- 1- Billing Address -----*/
/*----- 2- Shipping Address -----*/
.checkout .address form .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.checkout .address form .input-box .box{
    width: 49%;
}

.checkout .address form .box {
    width: 100%;
    padding: 1.2rem 0.5rem; 
    border-bottom: 0.1rem solid #333;
    font-size: 1.6rem;
    color: var(--grey);
    text-transform: none;
    margin: .7rem 0;
}

.checkout .address form .box::placeholder{
    text-transform: capitalize;
}

.checkout .address form .box:focus{
    border-color: var(--main-color);
}

/*----- 4- Payment Method -----*/
.payment-methods .item{
    width: 100%;
    margin-bottom: 1.5rem;
}

.payment-methods .item label{
    cursor: pointer;
    font-size: 2rem;
    color: var(--black);
}

.payment-methods .item  input{
    margin-right: 0.5rem;
}

.payment-methods .item input:checked + label, 
.payment-methods .item label:hover { 
    color: var(--main-color);
}

.payment-methods .item .payment-body { 
    display: none;
}

.payment-methods .item .payment-body p{
    font-size: 1.5rem;
    line-height: 2;
    color: var(--grey);
}

.payment-methods .item > input:checked ~ .payment-body { 
    display: block;
}

.payment-body.active{
    display: block; 
}

.checkout .btn{
    width: 100%;
    text-align: center;
}