.blog-info{
  background-color: var(--white);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  position: relative;
} 

.blog-info .date{
  position: absolute;
  top: 0rem;
  left: 0rem;
  color: var(--white);
  background-color: var(--main-color);
  opacity: 0.9;
  font-size: 2rem;
  padding: 2rem;
  text-align: center;
  width: 8rem;
}
 
.blog-info .image{ 
  height: 40rem; 
}

.blog-info .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.blog-info .content{
  padding: 2rem;   
}

.blog-info .content .details{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 0.2rem solid var(--main-color);
}

.blog-info .content .details .title{
  background-color: var(--main-color);
  color: var(--white);
  padding: 1rem 2rem;
  border-radius: 5rem;
}

.blog-info .content .details .admin{
  color: var(--black);
  font-weight: bold;
  font-size: 2rem;
}

.blog-info .content h5{
  color: var(--main-color);
  font-size: 1.5rem;
}

.blog-info .content h5 i{
  padding-right: 0.5rem;
}

.blog-info .content .main-heading{
  display: inline-block;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--black);
  padding: 2rem 0;
}

.blog-info p{
  padding-bottom: 2rem;
  font-size: 1.6rem;
  color: var(--grey);
  line-height: 1.5;
}
 
.blog-info .important{
  padding: 2rem 4rem;
  font-size: 1.5rem;
  color:  var(--black);
  line-height: 1.8;
  background-color: var(--light-brown);
  font-style: italic;
  border-left: 0.2rem solid var(--secondary-color);
  margin-bottom: 2rem;
}

.blog-info .blog-gallery{ 
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 0.5rem;
  padding: 2rem 0; 
}

.blog-info .blog-gallery img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.blog-info .end-details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
}

.blog-info .tags, 
.blog-info .share{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}

.blog-info .end-details h3{
  color: var(--black);
  font-size: 1.8rem;
  padding-right: 1rem;
}

.blog-info .tags span,
.blog-info .share .icon{
  color: var(--grey);
  font-size: 1.6rem;
}

.blog-info .share .icon:hover{
    color: var(--main-color);
    cursor: pointer;
}

  