.logo{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
}  

.logo img{
    height: 3rem;
    object-fit: cover;
}

.logo h3{
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--white);
    text-transform: capitalize;
    letter-spacing: 0.2rem;
}
