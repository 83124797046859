.about{
    padding: 2rem 0;  
} 

.about .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 2rem 5%;
    gap: 4rem;
}

.about .image{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 42rem;
        flex: 1 1 42rem;
    text-align: center;
}

.about .image img{
    width: 50rem;
    height: 40rem;
    object-fit: cover;
}

.about .content{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
}

.about .content h2{
    color: var(--black);
    font-size: 4rem;
    letter-spacing: 0.1rem;
    margin-bottom: 2rem;
}

.about .content h5{
    color: var(--black);
    font-size: 1.8rem;
    line-height: 1.5;
    padding-bottom: 1.5rem;
}

.about .content p{
    color: var(--grey);
    font-size: 1.6rem;
    line-height: 1.8;
    padding-bottom: 1.5rem;
}

.about .content .btn{
    margin-top: 1rem;
}