.gallery .box-container{
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
    gap: 1.5rem;
    padding: 2rem 0; 
}

.gallery .controls{ 
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    list-style-type: none;
    gap: 2rem;
    margin: 2rem 0;
}

