.btn{
    display: inline-block;
	padding: 1rem 3rem;
	color: var(--white);
    background-color: var(--secondary-color);
	font-size: 1.8rem;
	letter-spacing: 0.1rem;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.btn:hover{
    background-color: var(--main-color);
    cursor: pointer;
}
