.footer{
    background-color: var(--secondary-color); 
    padding: 0rem 5%;
}

.footer .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    gap: 3rem;
    padding: 2rem 0;
} 
 
.footer-item .logo{
    padding-bottom: 2rem;
} 

.footer-item .logo h3{ 
    color: var(--white);
}

.footer-item h2{
    color: var(--white);
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    padding-bottom: 2rem;
}

.footer-item > p{
    color: var(--white);
    font-size: 1.6rem;
    line-height: 1.6;
} 

.footer-item .info p{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
    line-height: 1.6;
}

.footer-item .info .icon{
    color: var(--main-color);
}

.footer-item .info a,
.footer-item .info p{
    font-size: 1.6rem;
    color: var(--white);
}

.footer-item .links p:hover{
    cursor: pointer;
}

.footer-item .links p:hover a{
    color: var(--main-color);
}

.footer-item .social{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
}

.footer-item .social a{
    display: inline-block;
    height: 4rem;
    width: 4rem;
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.footer-item .social a .icon{
    color: var(--main-color);
    font-size: 2rem;
}

.footer-item .social a:hover{
    background-color: var(--main-color);
}

.footer-item .social a:hover .icon{
    color: var(--white);
}

.footer-item .gmail{
    text-transform: none;
}

.footer .content{
    text-align: center;
    padding: 2rem 0;
    border-top: 0.2rem solid var(--white);
}

.footer .content p{
    font-size: 2rem;
    color: var(--white);
}

.footer .content p span{
    color: var(--main-color);
}

