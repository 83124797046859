.contact .contact-form h3{
    text-align: center;
    color: var(--black);
    font-size: 3rem;
    padding: 1rem 0;
}

.contact .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}

.contact .contact-form{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
    background: var(--white);
    padding: 1rem 2rem;
}

.contact .contact-form .box{
    width: 100%;
    font-size: 1.6rem;
    color: var(--black);
    text-transform: none;
    background-color: transparent;
    border-bottom: 0.1rem solid var(--black);
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
}

.contact .contact-form .box::placeholder{
    text-transform: capitalize;
    color: var(--grey);
}

.contact .contact-form .box:focus{
    border-color: var(--main-color);
}

.contact .contact-form textarea.box{
    width: 100%;
    height: 17rem;
    resize: none;
}

.contact .contact-form .alert{
    font-size: 2rem;
    color: var(--main-color);
    padding-left: 1rem;
}

/*----- 3- Google Map -----*/
.contact iframe{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 40rem;
        flex: 1 1 40rem;
}



  