.shop .shop-header{
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
    padding: 1rem;
    margin-bottom: 2rem;
    display: -webkit-box;
    display: -ms-flexbox; 
    display: flex; 
    -webkit-box-pack: justify;
    -ms-flex-pack: justify; 
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center; 
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; 
    gap: 1rem;
}

.shop .shop-header .showing{
    font-size: 1.6rem;
    color: var(--grey);
}

.shop .shop-header .styles{
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.shop .shop-header .styles a{
    font-size: 2rem;
    margin: 0rem 0.5rem;
    color: var(--black);
}

.shop .shop-header .styles a:hover{
    cursor: pointer;
    color: var(--main-color);
}

.shop .shop-header .sorting-type label{
    font-size: 1.6rem;
    color: var(--black);
    margin-right: 0.5rem;
    padding-right: 0.5rem;
}

.shop .shop-header .sorting-type select{
    font-size: 1.4rem;
    color: var(--grey);
    border: var(--border);
    border-radius: 0.5rem;
    padding: 1rem;
}
 
.shop .shop-header .sorting-type select option{
    padding: 1rem;
}