.faq{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center; 
    align-items: center;  
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 2rem;
}  
 
.faq .accordion-container {
    width: 100rem;
    margin: 0 2rem;
}

