.header{ 
    position: fixed;
    top: 0;
    left: 0;
    height: 6rem;  
    width: 100%;
    padding: 0rem 5%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -webkit-box-pack: justify; 
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; 
    z-index: 999;
    background-color: var(--secondary-color);
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow);
}
 
.header .navbar{
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}

.header .icon-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center; 
    align-items: center;
}

.header .icon-container .icon{
    font-size: 2rem;
    color: var(--white);
    margin: 0 0.5rem;
    padding: 0.5rem;
    cursor: pointer;
}

.header .icon-container .icon:hover{
    color: var(--main-color);
}

.header .menu-btn{
    display: none;
}

.mobile-menu{
    display: none;
}


/*---------------- RESPONSIVE ----------------*/
@media (max-width: 991px){
    .header .menu-btn{
        display: block;
    } 

    .mobile-menu{
        display: block;
    }

    .header .navbar{
        display: none;
    }
}
























