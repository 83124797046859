/*--------------------- 0- Global CSS (Start) ---------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root{
  --main-color: #C69B7B; /*brown*/
  --secondary-color: #603601; /*dark-brown*/
  --light-brown: #F7CCAC;
  --black: #000;
  --white: #fff;
  --grey: #666;
  --border: 0.1rem solid rgba(0, 0, 0, 0.1);
  --border-radius: 1rem;
  --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

*{
  font-family: 'Roboto', sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-transform: capitalize;
  border: none;
  outline: none;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

*::selection{
  color: var(--white);
  background-color: var(--main-color);
}

html{
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body{
  background-color: #f7f7f7;
  overflow-x: hidden;
}

section{
  padding: 3rem 5%;
}

::-webkit-scrollbar{
  width: 1rem;
}

::-webkit-scrollbar-thumb{
  background-color: var(--main-color);
}

::-webkit-scrollbar-track{
  background: var(--secondary-color);
}

.swiper-pagination-bullet{
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--light-brown);
  opacity: 0.7;
}

.swiper-pagination-bullet-active{
  width: 3rem;
  background: var(--secondary-color);
  opacity: 1;
}

/*----- Form CSS -----*/
.form{
  padding: 2rem;
  background: var(--white);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  margin: 1rem auto;
  text-align: center;
}

.form h3 { 
  font-size: 2.5rem;
  padding-bottom: 1rem;
  color: #333;
  text-transform: uppercase;
  text-align: center;
}

.form .input-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.form .input-box .box{
    width: 49%;
}

.form .box {
    width: 100%;
  padding: 1.2rem 0.5rem;
  border-bottom: 0.1rem solid #333;
  font-size: 1.6rem;
  color: var(--grey);
  text-transform: none;
  margin: .7rem 0;
}

.form .box::placeholder{
    text-transform: capitalize;
}

.form p {
  padding-top: 2rem;
  font-size: 1.5rem;
  color: var(--grey);
  text-align: center;
  padding-bottom: 1rem;
}

.form .btn{
    margin: 0rem auto;
}

.form button{
  margin: 1rem 0;
}

/*--------------------- Responsive (Start) ---------------------*/
@media (max-width: 991px){
  html{
    font-size: 55%;
  }

  .blog.grid, .blog.list{
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }

}

@media (max-width: 768px){

  section, .header, .footer{
    padding: 2rem;
  }

  .home .content{
    padding: 0 14%;
  }

  .contact > .box-container{
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .shop{
    -ms-flex-wrap: wrap-reverse;
        flex-wrap: wrap-reverse;
  }

  .testimonial-slider .swiper-button-next{
    right: 0rem;
  }
  
  .testimonial-slider .swiper-button-prev{
    left: 0rem;
  }

  .testimonial-slider{
      padding: 2rem 8rem 4rem 8rem;
  }


}

@media (max-width: 450px){

  html{
      font-size: 50%;
  }

  section, .header, .footer{
      padding: 1rem;
  }

  .form .input-box .box{
    width: 100%;
  }

  .cart .cart-total{
    width: 100%;
  }

  .reservation .booking-table .input-box .box{
    width: 100%;
  }

  .checkout .address form .input-box .box{
    width: 100%;
  }

  .leave-reply form .input-box .box{
    width: 100%;
  }

  .edit-address form .box {
    width: 100%;
  }

  .contact .form{
    width: 100%;
  }

}

/*--------------------- Responsive (End) ---------------------*/
