.blog-item{
  background-color: var(--white);
  position: relative;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}
 
.blog-item .date{
  position: absolute;
  top: 0rem;
  left: 0rem; 
  color: var(--white);
  background-color: var(--main-color);
  opacity: 0.9;
  font-size: 2rem;
  padding: 2rem;
  text-align: center;
  width: 8rem;
}

.blog-item .image{
  height: 25rem;
}

.blog-item .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.blog-item .content{
  padding: 2rem;  
}

.blog-item .content .details{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 1.5rem;
}

.blog-item .content .details .title{
  background-color: var(--main-color);
  color: var(--white);
  padding: 1rem 2rem;
  border-radius: 5rem;
}

.blog-item .content .details .admin{
  color: var(--black);
  font-weight: bold;
}

.blog-item .content .details h5{
  color: var(--main-color);
  font-size: 1.5rem;
}

.blog-item .content .main-heading{
  display: inline-block;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--black);
  padding-top: 1rem;
}

.blog-item p{
  padding: 1.5rem 0;
  font-size: 1.6rem;
  color: var(--grey);
  line-height: 1.5;
}
