.cart .shopping-cart .item{
    display: -webkit-box;
    display: -ms-flexbox; 
    display: flex; 
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; 
    -webkit-box-align: center;
    -ms-flex-align: center; 
    align-items: center; 
    padding-bottom: 1rem;
}  

.cart .shopping-cart .item .box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 20rem;
        flex: 1 1 20rem;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: var(--black);
}

.cart .shopping-cart .item .box.name{
    color: var(--black);
}

.cart .shopping-cart .item .total{
    color: var(--main-color);
}

.cart .shopping-cart .item .action{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cart .shopping-cart .item .icon{
    font-size: 2rem;
    cursor: pointer;
    margin: 0rem 0.5rem;
}

.cart .shopping-cart .item .icon:hover{
    color: var(--black);
}

.cart .shopping-cart .item .delete{
    color: red;
}

.cart .shopping-cart .item img{ 
    height: 15rem;
    width: 15rem;
    object-fit: cover;
    background-color:#f7f7f7;
}
